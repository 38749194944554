exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-eng-tsx": () => import("./../../../src/pages/contact-us/eng.tsx" /* webpackChunkName: "component---src-pages-contact-us-eng-tsx" */),
  "component---src-pages-contact-us-ita-tsx": () => import("./../../../src/pages/contact-us/ita.tsx" /* webpackChunkName: "component---src-pages-contact-us-ita-tsx" */),
  "component---src-pages-cookies-eng-tsx": () => import("./../../../src/pages/cookies/eng.tsx" /* webpackChunkName: "component---src-pages-cookies-eng-tsx" */),
  "component---src-pages-cookies-ita-tsx": () => import("./../../../src/pages/cookies/ita.tsx" /* webpackChunkName: "component---src-pages-cookies-ita-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-best-practice-eng-tsx": () => import("./../../../src/pages/legal/best-practice/eng.tsx" /* webpackChunkName: "component---src-pages-legal-best-practice-eng-tsx" */),
  "component---src-pages-legal-best-practice-ita-tsx": () => import("./../../../src/pages/legal/best-practice/ita.tsx" /* webpackChunkName: "component---src-pages-legal-best-practice-ita-tsx" */),
  "component---src-pages-legal-eng-tsx": () => import("./../../../src/pages/legal/eng.tsx" /* webpackChunkName: "component---src-pages-legal-eng-tsx" */),
  "component---src-pages-legal-impressum-eng-tsx": () => import("./../../../src/pages/legal/impressum/eng.tsx" /* webpackChunkName: "component---src-pages-legal-impressum-eng-tsx" */),
  "component---src-pages-legal-impressum-ita-tsx": () => import("./../../../src/pages/legal/impressum/ita.tsx" /* webpackChunkName: "component---src-pages-legal-impressum-ita-tsx" */),
  "component---src-pages-legal-ita-tsx": () => import("./../../../src/pages/legal/ita.tsx" /* webpackChunkName: "component---src-pages-legal-ita-tsx" */),
  "component---src-pages-legal-terms-of-use-eng-tsx": () => import("./../../../src/pages/legal/terms-of-use/eng.tsx" /* webpackChunkName: "component---src-pages-legal-terms-of-use-eng-tsx" */),
  "component---src-pages-legal-terms-of-use-ita-tsx": () => import("./../../../src/pages/legal/terms-of-use/ita.tsx" /* webpackChunkName: "component---src-pages-legal-terms-of-use-ita-tsx" */),
  "component---src-pages-maintenaince-tsx": () => import("./../../../src/pages/maintenaince.tsx" /* webpackChunkName: "component---src-pages-maintenaince-tsx" */),
  "component---src-pages-privacy-policy-app-tsx": () => import("./../../../src/pages/privacy-policy-app.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-app-tsx" */),
  "component---src-pages-privacy-policy-eng-tsx": () => import("./../../../src/pages/privacy-policy/eng.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-eng-tsx" */),
  "component---src-pages-privacy-policy-ita-tsx": () => import("./../../../src/pages/privacy-policy/ita.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-ita-tsx" */),
  "component---src-pages-terms-conditions-eng-tsx": () => import("./../../../src/pages/terms-conditions/eng.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-eng-tsx" */),
  "component---src-pages-terms-conditions-ita-tsx": () => import("./../../../src/pages/terms-conditions/ita.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-ita-tsx" */),
  "component---src-pages-user-manual-eng-tsx": () => import("./../../../src/pages/user-manual/eng.tsx" /* webpackChunkName: "component---src-pages-user-manual-eng-tsx" */),
  "component---src-pages-user-manual-ita-tsx": () => import("./../../../src/pages/user-manual/ita.tsx" /* webpackChunkName: "component---src-pages-user-manual-ita-tsx" */)
}

